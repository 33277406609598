<mat-grid-list cols="4" rowHeight="1:2">
  <mat-grid-tile>
    <mat-card class="scooter-tile">
      <img mat-card-image src="/assets/images/scooter.jpg">
      <mat-card-content>
        <p>
          Elektrische scooter
        </p>
      </mat-card-content>
      <mat-card-actions>
        <button color="primary" mat-raised-button routerLink="/reserveren">Reserveren</button>
      </mat-card-actions>
    </mat-card>
  </mat-grid-tile>
  <mat-grid-tile>
    <mat-card class="scooter-tile">
      <img mat-card-image src="/assets/images/scooter.jpg">
      <mat-card-content>
        <p>
          Elektrische scooter
        </p>
      </mat-card-content>
      <mat-card-actions>
        <button color="primary" mat-raised-button routerLink="/reserveren">Reserveren</button>
      </mat-card-actions>
    </mat-card>
  </mat-grid-tile>
  <mat-grid-tile>
    <mat-card class="scooter-tile">
      <img mat-card-image src="/assets/images/scooter.jpg">
      <mat-card-content>
        <p>
          Elektrische scooter
        </p>
      </mat-card-content>
      <mat-card-actions>
        <button color="primary" mat-raised-button routerLink="/reserveren">Reserveren</button>
      </mat-card-actions>
    </mat-card>
  </mat-grid-tile>
  <mat-grid-tile>
    <mat-card class="scooter-tile">
      <img mat-card-image src="/assets/images/scooter.jpg">
      <mat-card-content>
        <p>
          Elektrische scooter
        </p>
      </mat-card-content>
      <mat-card-actions>
        <button color="primary" mat-raised-button routerLink="/reserveren">Reserveren</button>
      </mat-card-actions>
    </mat-card>
  </mat-grid-tile>
</mat-grid-list>