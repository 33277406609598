<mat-vertical-stepper [linear]="true" #stepper>
  <mat-step [stepControl]="firstFormGroup">
    <form [formGroup]="firstFormGroup">
      <ng-template matStepLabel>Huur periode</ng-template>
      <mat-form-field appearance="fill">
        <mat-label>Kies de huur periode</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate formControlName="startDate" placeholder="Start datum">
          <input matEndDate formControlName="endDate" placeholder="Eind datum">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
      <div>
        <button mat-button matStepperNext>Volgende</button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="secondFormGroup">
    <form [formGroup]="secondFormGroup">
      <ng-template matStepLabel>Contact gegevens</ng-template>
      <mat-form-field>
        <mat-label>Naam</mat-label>
        <input matInput formControlName="name" placeholder="bijv. E. Korving" required>
      </mat-form-field>
      <br />
      <mat-form-field>
        <mat-label>Telefoon</mat-label>
        <input matInput formControlName="phone" placeholder="0612345678" required>
      </mat-form-field>
      <br />
      <mat-form-field>
        <mat-label>E-mailadres</mat-label>
        <input matInput formControlName="email" placeholder="ex@mple.com" required>
      </mat-form-field>
      <br />
      <div>
        <button mat-button matStepperPrevious>Terug</button>
        <button mat-button matStepperNext>Volgende</button>
      </div>
    </form>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Afrekenen</ng-template>
    <p>Alleen nog even betalen!</p>
    <div>
      <button mat-button matStepperPrevious>Terug</button>
      <button mat-button (click)="stepper.reset()">Opnieuw</button>
    </div>
  </mat-step>
</mat-vertical-stepper>